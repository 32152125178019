<template>
  <div class="selectorlist">
    <dl>
      <dd class="search">
        <input title="search" type="text" class="lsc-input lsc-rounded list_searchbar"
               v-model="searchText" :placeholder='x("searchDots")'><i class='fa fa-search'></i>
      </dd>
      <dd class="sort">
        Sorteret efter:
        <a @click="toggleSortOptions()" class="defaultSort">{{ x(sortLabel) }}</a>
        <a @click="toggleSortDirection()" class="sort_direction">
          <icon :name="sortAscending === true ? 'fa-sort-amount-asc': 'fa-sort-amount-desc'" />
        </a>

        <ul class='sortList' v-show="sortOptionsVisible && hasLoaded">
          <li :class="{ loading: isRequestingPosition }" v-if="hasPosition || isRequestingPosition">
            <a @click="setSortProperty('DistKM')">
              {{ x('distance') }}
              <span v-if="isRequestingPosition"><icon name="fa-cog fa-spin"/></span>
            </a>
          </li>
          <li><a @click="setSortProperty('name')">{{ x('name') }}</a></li>
          <li><a @click="setSortProperty('city')">{{ x('city') }}</a></li>
          <li><a @click="setSortProperty('address')">{{ x('address') }}</a></li>
          <li><a @click="setSortProperty('postalCode')">{{ x('postalCode') }}</a></li>
        </ul>
      </dd>
      <dd class="create"></dd>
      <dd class="content customerList">
        <loading-sign v-if="!hasLoaded"/>
        <customer-list-item
          v-for="item in shownItems"
          :key="item.id"
          :item="item"
          :sort-by="sortBy"
          @click="itm => showCustomer(itm)" />
        <button v-if="showLoadMoreButton" @click='showMoreItems'
                class='lsc-button lsc-rounded-10 grey b_LoadMore'>{{ x('loadMore') }}
        </button>
      </dd>
    </dl>
    <!--<div class="content element materialShadow"></div>-->
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import { reverse, sortBy, take } from 'lodash';
  import { httpGet } from '@/classes/httpHelper';
  import { getDistanceFromLatLongInKm } from '@/classes/location-helper';
  import Events from '@/enums/event-names';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';
  import CustomerListItem from './CustomerListItem';
  import LoadingSign from '../LoadingSign';

  /**
   * This is a front-end list used when clicking "customer list" in the left menu.
   * It is similar to the list of customers in the popup under Take Picture, but
   * those are actually different components, with different functionalities. This
   * list does not support selecting an item, however it supports opening a customer
   * page (CustomerPage).
   */
  export default {
    name: 'customer-list',
    props: {
      title: String,
      preloadedItems: Array,
    },
    components: {
      'customer-list-item': CustomerListItem,
      'loading-sign': LoadingSign,
    },
    mixins: [translate],
    emits: [ComponentEventNames.didLoad],
    data() {
      return {
        sortOptionsVisible: false,
        allItems: [],
        sortBy: '',
        sortAscending: true,
        hasLoaded: false,
        isRequestingPosition: false,
        listState: {
          amount: 0, /** The number of items being shown in the list right now. */
          cachedPosition: null, /** The position of the user at the time the customer
           list was retrieved. Is significant because the list is sorted by distance
           to this location. */
        },
        searchText: '',
        labels: {
          DistKM: 'distance',
          name: 'name',
          city: 'city',
          address: 'address',
          postalCode: 'postalCode',
        },
      };
    },
    computed: {
      showLoadMoreButton() {
        return this.listState.amount < this.filteredItems.length;
      },
      filteredItems() {
        if (this.searchText === '') {
          return this.allItems;
        }
        const lowercaseFilter = this.searchText.toLowerCase();
        return this.allItems.filter((v) => {
          if (v.name && v.name.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.address && v.address.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.city && v.city.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.postalCode && v.postalCode.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.extra1 && v.extra1.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          return false;
        });
      },
      shownItems() {
        return take(this.filteredItems, this.listState.amount);
      },
      sortLabel() {
        if (this.sortBy === '' || this.sortBy === 'sortIndex') return 'standard';
        if (this.labels[this.sortBy] === undefined) return this.sortBy;
        return this.labels[this.sortBy];
      },
      hasPosition() {
        return this.position != null;
      },
      ...mapState(['user', 'config']),
      ...mapState('LocationStore', ['position']),
      ...mapState('CachingStore', {
        cachingPosition: (state) => state.cachingPosition,
        customerCache: (state) => state.customers,
      }),
    },
    async mounted() {
      this.registerEventHandlers();
      this.$emit(ComponentEventNames.didLoad);
      this.initLocation();
      await this.loadCustomers();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.customerDeleted, this.onCustomerDeleted);
        this.$bus.on(Events.customerUpdated, this.onCustomerUpdated);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.customerDeleted, this.onCustomerDeleted);
        this.$bus.off(Events.customerUpdated, this.onCustomerUpdated);
      },
      initLocation() {
        // TODO: Use LocationStore
        this.isRequestingPosition = true;
        // 15 seconds timeout for retrieving location
        const options = { timeout: 15000 };
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const coordinates = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              };
              this.$store.commit('LocationStore/setLocation', coordinates);
              if (this.allItems.length > 0) {
                this.setDistanceOnCustomers(coordinates);
              }
              this.isRequestingPosition = false;
            },
            (error) => {
              console.warn('Error while trying to retrieve position: ', error);
            },
            options,
          );
        }
      },
      resetAmount() {
        this.listState.amount = Math.min(100, this.filteredItems.length);
      },
      async loadCustomers() {
        this.sortAscending = true;
        this.sortBy = this.position == null ? 'name' : 'DistKM';
        let locationHasChanged = false;
        if (this.cachingPosition != null && this.hasPosition) {
          const distanceInMeter = getDistanceFromLatLongInKm(
            this.position,
            this.cachingPosition,
          ) * 1000;
          locationHasChanged = distanceInMeter > 50;
        }

        if (this.customerCache === null || locationHasChanged) {
          let url = '';
          if (this.position == null) {
            url = `customers?userrole=${this.user.userRole}`;
          } else {
            // Is it internal or external that should only show customers in same channel?
            const channelParam = this.user.userRole === 'internal' ? '&channelonly=true' : '';
            url = `customers/bydistance?lat=${this.position.latitude}&long=${this.position.longitude}${channelParam}`;
          }
          const loadedCustomers = await httpGet(url);

          if (!loadedCustomers.error) {
            this.$store.commit('CachingStore/setCustomerCacheAndPosition', {
              customers: loadedCustomers,
              cachingPosition: this.position,
            });

            const threeDaysInMilliseconds = 1000 * 60 * 60 * 24 * 3;
            setTimeout(() => {
              this.$store.commit('CachingStore/setCustomerCacheAndPosition', {
                customers: null,
                cachingPosition: null,
              });
              this.$store.commit('CachingStore/setNoCustomer', null);
            }, threeDaysInMilliseconds);

            this.listState.amount = Math.min(100, loadedCustomers.length);
            // Use basic sorting from Stored Procedure - previous: this.sortList(data);
            this.allItems = this.sortList(loadedCustomers);
            this.resetAmount();

            if (this.hasPosition) {
              this.setDistanceOnCustomers(this.position);
            }
          } else {
            // eslint-disable-next-line no-alert
            alert(this.x('customerRetrievalFailedMessage'));
          }
        } else {
          // Use basic sorting from Stored Procedure
          this.allItems = this.sortList(this.customerCache);
          this.resetAmount();
        }
        this.hasLoaded = true;
      },
      sortList(list) {
        // If we sort by sortIndex or name, the sort is by that property alone.
        // If sortBy is something else, we start by sorting by that property, then by name.
        // NOTE: We assume that all data has a 'name' property. This might fail, if they don't.
        if (this.sortBy === 'sortIndex' || this.sortBy === 'name') {
          console.log('Sorting by ', this.sortBy);
          return sortBy(list, this.sortBy);
        }
        console.log(`Sorting by ${this.sortBy}, then by name.`);
        return sortBy(list, [this.sortBy, 'name']);
      },
      setDistanceOnCustomers(hereCoordinates) {
        console.log('Starting to set distance on customers');

        this.allItems.forEach((c) => {
          // eslint-disable-next-line no-param-reassign
          c.DistKM = getDistanceFromLatLongInKm(hereCoordinates, c);
        });

        console.log('Finished setting distance on customers');
      },
      showCustomer(customer) {
        // Open customer page for that customer
        console.log('Customer: ', customer);
        const CustomerPage = defineAsyncComponent(() => import('./CustomerPage'));
        this.$store.commit('pushPopup', {
          component: CustomerPage,
          title: customer.name,
          params: {
            customer,
          },
          direction: 'left',
        });
      },
      toggleSortDirection() {
        console.log('toggleSort');
        this.sortAscending = !this.sortAscending;
        this.allItems = reverse(this.allItems);
        // For some reason, the reversing of the array does not trigger reactive updates,
        // that is why we push and pop an item.
        this.allItems.push({});
        this.allItems.pop();
      },
      setSortProperty(propName) {
        console.log('Prop name: ', propName);
        this.sortBy = propName;
        this.allItems = this.sortList(this.allItems);
        this.toggleSortOptions();
      },
      showMoreItems() {
        this.listState.amount = Math.min(this.listState.amount + 100, this.filteredItems.length);
      },
      toggleSortOptions() {
        this.sortOptionsVisible = !this.sortOptionsVisible;
      },
      close_list() {
        if (typeof this.callback === 'function') {
          this.callback(this.selectedItems);
        }
        this.$store.commit('popPopup');
      },
      onCustomerDeleted(params) {
        this.allItems = this.allItems.filter((item) => item.id !== params.customerId);
        this.$store.commit('CachingStore/setCustomerCache', this.allItems);
      },
      onCustomerUpdated(customer) {
        const index = this.allItems.findIndex((c) => c.id === customer.id);
        this.allItems.splice(index, 1, customer);
      },
    },
  };
</script>

<style lang="scss" src="../SelectorList/selectorlist.scss"></style>
