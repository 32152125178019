<template>
  <a id='productID_${item.id}'
     @click="click()"
     class='select_list ThemeAppListItem'
     v-html="contents"/>
</template>

<script>
  import translate from '@/components/Mixins/Translate';

  /**
   * This component can show a customer as a list item.
   * It can be clicked.
   */
  export default {
    name: 'customer-list-item',
    props: {
      item: {
        type: Object,
      },
      sortBy: {
        type: String,
        default: 'name',
      },
    },
    emits: ['click'],
    mixins: [translate],
    data() {
      return {
        renderers: {
          DistKM: (item) => `${item.name}<p><b>${Math.round(item.DistKM || 0)} Kilometer</b>
${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          name: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          city: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          address: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          postalCode: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
        },
      };
    },
    computed: {
      contents() {
        return this.renderers[this.sortBy](this.item);
      },
    },
    methods: {
      click() {
        this.$emit('click', this.item);
      },
    },
  };
</script>

<style scoped lang="scss">

</style>
